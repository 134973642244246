// import { DropdownInputProps } from "./types";
import { useTranslation } from "react-i18next";
import dropdownStyle from "./index.module.scss";

const DropdownInput = (props: any) => {
	const { t } = useTranslation();
	let readOnly = props.readonly === true;
	let value = props.value || "";

	const handleSubmit = (e) => {
		e.preventDefault();
		if (props?.onSubmit) {
			props.onSubmit();
		}
	};

	return (
		<span className="inline-block w-full rounded-md shadow-sm">
			<form onSubmit={handleSubmit}>
				<input
					type="text"
					id="select"
					placeholder={t(props.placeholder)}
					value={t(value)}
					autoComplete="off"
					className={dropdownStyle.input}
					onChange={props.onChange}
					onFocus={props.onFocus}
					onClick={props.onClick}
					readOnly={readOnly}
				/>
			</form>
		</span>
	);
};

export default DropdownInput;
