/* eslint-disable */

import { useTranslation } from "react-i18next";
import { GetPerformanceClassName } from "./PerformanceClass";
import GetAllDataPoints from "./DataPoints";
import { FullScoreCardFilters } from "./FullScoreCardFilters";
import { FullScoreCardStat } from "./FullScoreCardStat";
import "./ScoreCard.scss";
import "./FullScoreCard.scss";
import { useEffect, useRef, useState } from "react";
import DropdownLanguages from "../DropdownLanguages";
import { ScoreCardLegends } from "./ScoreCardLegends";
import ScoreCardTitle from "./ScoreCardTitle";
import IndicatorBar from "./IndicatorBar";
import { useClickAway } from "react-use";
import MoreInfoData from "./moreInfoData/MoreInfoData";
import getMoreInfoByKey from "../../api/moreInfo/getMoreInfoByKey";

const FullScoreCard = ({
	scorecard,
	cardCut,
	onClick,
	onClickSimilar,
	totalAreas,
	setScorecardView,
}) => {
	const myRef = useRef(null);
	const moreInfoRef = useRef(null);
	const [isScrolled, setIsScrolled] = useState(false);
	const [allDataPoints, setDataPoints] = useState(
		GetAllDataPoints(scorecard)
	);
	const [moreInfoData, setMoreInfoData] = useState(null);
	const [lastMoreInfo, setLastMoreInfo] = useState(null);
	const { t, i18n } = useTranslation();
	useClickAway(moreInfoRef, (evt) => {
		closeMoreInfo();
	});
	useEffect(() => {
		if (!isScrolled) {
			let myDiv = document.getElementById("scoreCardContainer");
			myDiv.scrollTop = 0;
			setIsScrolled(true);
		}
	});
	const allTables = [];
	const allFilters = [];
	const filterPointClick = (pointData, level) => {
		pointData.selected = !pointData.selected;
		for (let a = 0; a < pointData.points.length; a++) {
			pointData.points[a].selected = pointData.selected;
			if (level == "1") {
				for (let b = 0; b < pointData.points[a].points.length; b++) {
					pointData.points[a].points[b].selected =
						pointData.points[a].selected;
				}
			}
		}
		setDataPoints([...allDataPoints]);
	};

	const languageChanged = (val) => {
		for (let a = 0; a < allDataPoints.length; a++) {
			let pA = allDataPoints[a];
			pA.title = t(pA.title_key);
			//console.log("pA.title: "+pA.title);
			for (let b = 0; b < pA.points.length; b++) {
				let pB = pA.points[b];
				pB.title = t(pB.title_key);
				for (let c = 0; c < pB.points.length; c++) {
					let pC = pB.points[c];
					pC.title = t(pC.title_key);
				}
			}
		}
		setDataPoints([...allDataPoints]);
	};

	const getLanguage = () => {
		switch (i18n.language) {
			case "en":
				return "english";
			case "es":
				return "spanish";
			case "ko":
				return "korean";
			case "vi":
				return "vietnamese";
			case "zhs":
				return "chineseSimplified";
			case "zht":
				return "chineseTraditional";
			default:
				return "english";
		}
	};

	const fetchMoreInfoByKey = async (key) => {
		try {
			const { data } = await getMoreInfoByKey(key);
			if (data?.[getLanguage()].definition) {
				setMoreInfoData({
					...data[getLanguage()],
					key,
				});
			} else if (data?.["english"].definition) {
				setMoreInfoData({
					...data["english"],
					key,
				});
			} else {
				setMoreInfoData("No information found.");
			}
		} catch (err) {
			console.log(`Unable to fetch more info by key: ${err.message}`);
		}
	};

	const showMoreInfo = (evt, dp) => {
		if (lastMoreInfo != null) {
			lastMoreInfo.className = "stat-table-more-info";
		}
		let elem = moreInfoRef.current;
		let parentElem = document.getElementById("scoreCardContainer");
		elem.style.top =
			(evt.clientY + parentElem.scrollTop + 15).toString() + "px";
		elem.style.right = "50px";
		elem.style.display = "block";

		// const selectedInfoData = infoData[dp.mi_key];
		// setMoreInfoData(selectedInfoData);
		fetchMoreInfoByKey(dp.mi_key);
	};

	const closeMoreInfo = () => {
		// console.log("closeMoreInfo");
		let elem = moreInfoRef.current;
		elem.style.display = "none";
		setMoreInfoData(null);
		if (lastMoreInfo != null) {
			lastMoreInfo.className = "stat-table-more-info";
		}
		setLastMoreInfo(null);
	};

	const downloadPdf = (saveFile: boolean = true) => {
		onClick();
	};

	allTables.push(
		<FullScoreCardStat
			moreInfoClick={showMoreInfo}
			key="table1"
			tableData={allDataPoints[0]}
			onClick={filterPointClick}
			totalAreas={totalAreas}
		></FullScoreCardStat>
	);
	allTables.push(
		<FullScoreCardStat
			moreInfoClick={showMoreInfo}
			key="table2"
			tableData={allDataPoints[1]}
			onClick={filterPointClick}
			totalAreas={totalAreas}
		></FullScoreCardStat>
	);
	allTables.push(
		<FullScoreCardStat
			moreInfoClick={showMoreInfo}
			key="table3"
			tableData={allDataPoints[2]}
			onClick={filterPointClick}
			totalAreas={totalAreas}
		></FullScoreCardStat>
	);
	allFilters.push(
		<FullScoreCardFilters
			key="filter1"
			pointData={allDataPoints[0]}
			onClick={filterPointClick}
		></FullScoreCardFilters>
	);
	allFilters.push(
		<FullScoreCardFilters
			key="filter2"
			pointData={allDataPoints[1]}
			onClick={filterPointClick}
		></FullScoreCardFilters>
	);
	allFilters.push(
		<FullScoreCardFilters
			key="filter3"
			pointData={allDataPoints[2]}
			onClick={filterPointClick}
		></FullScoreCardFilters>
	);

	const rowStyle = {
		background: "orange",
	};

	return (
		<div ref={myRef}>
			{/* Rameez new layout start */}
			<div className="full-sc-top-container">
				<div className="full-sc-top-part left">
					<div className="full-sc-top-card-title">
						<ScoreCardTitle
							scorecard={scorecard}
							cardCut={cardCut}
						></ScoreCardTitle>
					</div>
					<div
						className="stat-layout-big"
						style={{
							float: "right",
							textAlign: "left",
							paddingRight: "0px",
						}}
					>
						<div>
							<a
								id="see-scorecard-bot"
								className="btn-dawn-spi"
								onClick={() => downloadPdf(true)}
							>
								{t("download_pdf")}
							</a>
						</div>
						<div style={{ marginTop: "10px" }}>
							<DropdownLanguages
								onChange={languageChanged}
							></DropdownLanguages>
						</div>
					</div>
					<div style={{ clear: "both" }}></div>
					<div className="stat-layout-big">
						<hr style={{ width: "100%", marginTop: "5px" }}></hr>
						<div
							style={{
								marginTop: "20px",
								marginBottom: "15px",
							}}
						>
							<div
								style={{
									float: "left",
									backgroundColor: "rgba(86, 184, 187,0.1)",
									padding: "10px",
									borderRightWidth: "1px",
									borderRightStyle: "solid",
									borderRightColor: "rgba(0,0,0,0.15)",
								}}
							>
								<div style={{ textAlign: "center" }}>
									<div style={{ display: "inline-block" }}>
										<img
											src="assets/images/spi_marker_half.png"
											style={{
												height: "10px",
												display: "inline",
												marginTop: "-3px",
											}}
											alt=""
										/>
									</div>
									<div
										className="text-rameez-orange font-bold rank-heading"
										style={{
											display: "inline-block",
											marginLeft: "2px",
										}}
									>
										<b>SPI</b>
									</div>
								</div>
								<div className="font-bold rank-value">
									{isNaN(scorecard.spi.value)
										? "N/A"
										: `${Number(
												Number(
													scorecard.spi.value
												).toFixed(0)
										  )} / 100`}
								</div>
							</div>
							<div
								style={{
									float: "left",
									backgroundColor: "rgba(86, 184, 187,0.1)",
									padding: "10px",
								}}
							>
								<div style={{ textAlign: "center" }}>
									<div style={{ display: "inline-block" }}>
										<img
											src="assets/images/rank_marker_half.png"
											style={{
												height: "10px",
												display: "inline",
												marginTop: "-3px",
											}}
											alt=""
										/>
									</div>
									<div
										className="text-rameez-orange font-bold rank-heading"
										style={{
											display: "inline-block",
											marginLeft: "2px",
										}}
									>
										<b>{t("rank").toUpperCase()}</b>
									</div>
								</div>
								<div className="font-bold rank-value">
									{scorecard.spi.rank} / {totalAreas}
								</div>
							</div>
							<div
								className="full-sc-overall-spi"
								style={{
									float: "right",
									paddingRight: "15px",
								}}
							>
								<div className="section">
									<IndicatorBar
										width="280px"
										spi={scorecard.spi.value}
										rank={scorecard.spi.rank}
										totalAreas={totalAreas}
									></IndicatorBar>
								</div>
								<div className="section">
									<div
										className={GetPerformanceClassName(
											scorecard.spi.performance,
											"w-6 h-6"
										)}
									/>
								</div>
								<div className="section">
									<div className="text">
										{t("compared_to")}
										<br />
										{t("last_year")}
									</div>
								</div>
								<div className="section">
									{scorecard.spi.compared_to_last_year >=
										0 && (
										<img
											src="assets/images/arrow_top_right.png"
											style={{ width: "20px" }}
											alt=""
										/>
									)}
									{scorecard.spi.compared_to_last_year <
										0 && (
										<img
											src="assets/images/arrow_down_right.png"
											style={{ width: "20px" }}
											alt=""
										/>
									)}
								</div>
							</div>
							<div style={{ clear: "both" }}></div>
						</div>
					</div>
				</div>
				<div className="full-sc-top-part right">
					<ScoreCardLegends wh="3.5"></ScoreCardLegends>
				</div>
				<div style={{ clear: "both" }}></div>
			</div>
			<div className="stat-layout-small">
				<div style={{ marginTop: "10px" }}>
					<div style={{ marginTop: "5px" }}>
						<IndicatorBar
							width="100%"
							spi={scorecard.spi.value}
							rank={scorecard.spi.rank}
							totalAreas={totalAreas}
						></IndicatorBar>
					</div>
					<table
						style={{
							width: "100%",
							marginTop: "10px",
						}}
					>
						<tbody>
							<tr className="stat-table-row">
								<td className="spi font-bold">
									<div style={{ display: "inline-block" }}>
										<img
											src="assets/images/spi_marker_half.png"
											style={{
												height: "8px",
												display: "inline",
												marginTop: "-2px",
											}}
											alt=""
										/>
									</div>
									<div style={{ display: "inline-block" }}>
										<b>{t("spi").toUpperCase()}</b>
									</div>
									<br />
									<span className="text-rameez-orange">
										{isNaN(scorecard.spi.value)
											? "N/A"
											: Number(
													Number(
														scorecard.spi.value
													).toFixed(0)
											  )}
									</span>
								</td>
								<td className="spi font-bold">
									<div style={{ display: "inline-block" }}>
										<img
											src="assets/images/rank_marker_half.png"
											style={{
												height: "8px",
												display: "inline",
												marginTop: "-2px",
											}}
											alt=""
										/>
									</div>
									<div style={{ display: "inline-block" }}>
										<b>{t("rank").toUpperCase()}</b>
									</div>
									<br />
									<span className="text-rameez-orange">
										{isNaN(scorecard.spi.rank)
											? "N/A"
											: Number(
													Number(
														scorecard.spi.rank
													).toFixed(0)
											  )}
									</span>
								</td>
								<td style={{ width: "50px" }}>
									<div
										style={{ margin: "auto" }}
										className={GetPerformanceClassName(
											scorecard.spi.performance,
											"w-6 h-6"
										)}
									/>
								</td>
								<td className="compare-to">
									{t("compared_to")}
									<br />
									{t("last_year")}
								</td>
								<td style={{ width: "20px" }}>
									<img
										src="assets/images/arrow_top_right.png"
										style={{ width: "15px" }}
										alt=""
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div className="full-sc-separator"></div>
				<div style={{ marginTop: "10px" }}>
					<div style={{ float: "left", width: "39%" }}>
						<DropdownLanguages></DropdownLanguages>
					</div>
					<div
						style={{
							float: "left",
							textAlign: "right",
							width: "60%",
						}}
					>
						<a
							id="see-scorecard-bot"
							className="btn-dawn-spi"
							onClick={() => downloadPdf(true)}
						>
							{t("download_pdf")}
						</a>
					</div>
					<div style={{ clear: "both" }}></div>
				</div>
				<div className="full-sc-separator"></div>
			</div>
			<div style={{ marginTop: "20px" }}>
				{/* Table sidebar */}
				<div className="full-sc-filters-section">
					<div className="full-sc-all-filters-container">
						{allFilters}
					</div>
				</div>

				{/* Table data */}
				<div className="full-sc-stats-section">
					<div className="full-sc-all-stats-container">
						{allTables}
					</div>
				</div>
				<div style={{ clear: "both" }}></div>
			</div>
			{/* Popover for more-info */}
			<div
				ref={moreInfoRef}
				className="more-info-popover"
				style={{ width: "100%", display: "none" }}
			>
				<div style={{ maxHeight: "400px", overflowY: "scroll" }}>
					{moreInfoData ? (
						<MoreInfoData data={moreInfoData} />
					) : (
						<h3>Loading...</h3>
					)}
				</div>
				<div
					style={{
						marginTop: "15px",
						textAlign: "center",
						width: "100%",
					}}
				>
					<button
						type="button"
						onClick={closeMoreInfo}
						className="more-info-close"
					>
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

export default FullScoreCard;
