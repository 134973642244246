/* eslint-disable */
import { useTranslation } from "react-i18next";
import Dropdown from "./Map/Dropdown";

const DropdownLanguages = (props) => {
	const languages = [
		{
			value: "en",
			label: "English",
		},
		{
			value: "es",
			label: "Español",
		},
		{
			value: "ko",
			label: "한국인",
		},
		{
			value: "vi",
			label: "Tiếng Việt",
		},
		{
			value: "zhs",
			label: "简体中文",
		},
		{
			value: "zht",
			label: "繁體中文",
		},
	];

	const { t, i18n } = useTranslation();

	const onChange = (opt) => {
		i18n.changeLanguage(opt.id, (err, tl) => {
			if (props.onChange) {
				props.onChange(opt.id);
			}
		});
	};

	let selected = null;

	const options = languages.map((o) => {
		let val = { id: o.value, title: o.label };
		if (o.value == i18n.language) {
			selected = val;
		}
		return val;
	});

	return (
		<>
			<div className="select-title">{t("language")}</div>
			<div>
				<Dropdown
					options={options}
					value={selected}
					onChange={onChange}
					placeholder=""
					autocomplete={false}
				></Dropdown>
			</div>
		</>
		// <select
		//   className="ddl-box"
		//   value={i18n.language}
		//   onChange={onLanguageChange}
		// >
		//   {languages.map((o) => (
		//     <option key={o.value} value={o.value}>
		//       {o.label}
		//     </option>
		//   ))}
		// </select>
	);
};

export default DropdownLanguages;
