/* eslint-disable */

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Dropdown from "./Dropdown";
import {
	GetBaseLayerSets,
	GetDefaultBaseLayerForSet,
	GetSpiQuartileOptions,
	HideSpiLevelForDropDown,
	ShuffleBaseLayersForDropdown,
} from "./Helpers/BaseLayer";
import getBaselayerDictionary from "../../api/baselayers/getBaselayerDictionary";

type BaseLayersOptionsProps = {
	selectedLayers: any[];
	setSelectedLayers: any;
	selectedQuartile: any;
	onChange?: (arg0: any[]) => void;
	onQuartileChange: (arg0: any) => void;
	onQuartileClear: () => void;
	selectedCutOptions: any;
	setSelectedCutOptions: any;
};

const BaseLayersOptions = (props: BaseLayersOptionsProps) => {
	const [previousValue, setPreviousValue] = useState(null);
	const [baseLayers, setBaseLayers] = useState([]);
	const [baseLayersTypes, setBaseLayersTypes] = useState(GetBaseLayerSets());
	const { t } = useTranslation();

	const loadBaseLayers = async () => {
		try {
			const response = await getBaselayerDictionary();
			let layers = response.data.filter(
				(layer: any) => layer.content_key !== "aic_broadband"
			);
			const sortedLayers = [];
			ShuffleBaseLayersForDropdown(layers, sortedLayers);
			setBaseLayers(sortedLayers);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		loadBaseLayers();
		// if (
		// 	(props.selectedCutOptions?.city || props.selectedCutOptions?.zip) &&
		// 	props.selectedLayers?.length > 0 &&
		// 	props.selectedLayers[0] != null
		// ) {
		// 	const layer = props.selectedLayers[0];
		// 	if (layer?.level > 2) {
		// 		let selectedType = null;
		// 		baseLayersTypes.map((val) => {
		// 			selectedType = val.selected ? val : selectedType;
		// 			return val;
		// 		});
		// 		alert(t("confirm_layer"));
		// 		selectDefaultLayer(selectedType);
		// 		props.setSelectedLayers(previousValue[0]);
		// 	}
		// }
	}, [props.selectedCutOptions]);

	useEffect(() => {
		if (
			!props.selectedLayers ||
			props.selectedLayers.length == 0 ||
			props.selectedLayers[0] == null
		) {
			let value = baseLayersTypes[0];
			let selectedType = null;
			const newTypes = baseLayersTypes.map((val) => {
				val.selected = val.type == value.type;
				selectedType = val.selected ? val : selectedType;
				return val;
			});
			setBaseLayersTypes(newTypes);
			selectDefaultLayer(selectedType);
		}
	}, [props.selectedLayers]);

	const getSelectedSet = () => {
		return baseLayersTypes.find((val) => {
			return val.selected;
		});
	};

	const selectDefaultLayer = (selType: any) => {
		const newLayers = GetDefaultBaseLayerForSet(baseLayers, selType);
		if (props.onChange) {
			props.onChange(newLayers);
		}
	};

	const onClear = () => {
		if (props.onChange) {
			props.onChange([]);
		}
	};

	const onToggle = (value) => {
		let newBls = baseLayers.map((val) => {
			if (val.id == value.id) {
				val.is_open = !val.is_open;
			}
			return val;
		});
		setBaseLayers(newBls);
		return;
	};

	const onChange = (value) => {
		if (value.id == "0") {
			console.error("value.id=0");
			return;
		}
		setPreviousValue(props.selectedLayers);
		let selLayers = [];
		for (let a = 0; a < baseLayers.length; a++) {
			if (baseLayers[a].id == value.id) {
				selLayers.push(baseLayers[a]);
				if (
					selectedType &&
					selectedType.type == "spi" &&
					value.level < 3 &&
					!value.is_open
				) {
					onToggle(value);
				}
				break;
			}
		}
		props.onChange?.call(this, selLayers);
	};

	const onQuartileClear = () => {
		props.onQuartileClear();
	};

	const onQuartileChanged = (value) => {
		props.onQuartileChange(value);
	};

	const baseLayersTypeClick = (value) => {
		if (value.selected) {
			return;
		}
		let selectedType = null;
		const newTypes = baseLayersTypes.map((val) => {
			val.selected = val.type == value.type;
			selectedType = val.selected ? val : selectedType;
			return val;
		});
		setBaseLayersTypes(newTypes);
		selectDefaultLayer(selectedType);
		props.onQuartileClear();
	};

	const hasSelected =
		props.selectedLayers &&
		props.selectedLayers.length > 0 &&
		props.selectedLayers[0] != null;
	if (!hasSelected && baseLayers.length > 0) {
		selectDefaultLayer(getSelectedSet());
	}
	const baseLayerValue = hasSelected ? props.selectedLayers[0] : null;
	const selectedType = getSelectedSet();

	const placeholderText = t("select");

	let options = [];
	const { selectedCutOptions } = props;
	if (selectedCutOptions?.city || selectedCutOptions?.zip) {
		options = baseLayers.filter((layer) => {
			if (
				!selectedType ||
				layer.layer_type != selectedType.type ||
				layer.content_key === "below200pov" ||
				layer?.level > 2
			) {
				return false;
			}
			layer.title = t(layer.title_key);
			return true;
		});
		options = options.map((option) => {
			if (option.level === 2) {
				return {
					id: option.id,
					content_key: option.content_key,
					layer_type: option.layer_type,
					level: option.level,
					parent: option.parent,
					title: option.title,
					title_key: option.title_key,
				};
			} else {
				return option;
			}
		});
	} else {
		options = baseLayers.filter((layer) => {
			if (
				!selectedType ||
				layer.layer_type != selectedType.type ||
				layer.content_key === "eq_carbonfootprint"
			) {
				return false;
			}
			layer.title = t(layer.title_key) || layer.title;
			return true;
		});
	}

	if (
		selectedType &&
		(selectedType.type == "cdc" || selectedType.type == "demographics")
	) {
		//check categories open and close
		for (let a = 0; a < options.length; a++) {
			let opt = options[a];
			if (opt.is_category === true && opt.is_open === false) {
				for (let b = a + 1; b < options.length; b++) {
					let optB = options[b];
					if (optB.parent == opt.id) {
						options.splice(b, 1);
						b--;
					}
				}
			}
		}
	} else if (selectedType && selectedType.type == "spi") {
		options = HideSpiLevelForDropDown(options);
	}

	const quartileOptions = GetSpiQuartileOptions();
	const selectedQuartile = props.selectedQuartile;

	let showClose = true;
	if (
		selectedType &&
		selectedType.type == "spi" &&
		props.selectedLayers &&
		props.selectedLayers.length > 0 &&
		props.selectedLayers[0] != null &&
		props.selectedLayers[0].content_key == "spi"
	) {
		showClose = false;
	}

	return (
		<div className="layer-options-container">
			<div className="categories">
				<div className="select-title">{t("baselayer_sets")}</div>
				<div>
					{baseLayersTypes.map((bl: any) => {
						return (
							<div
								className={`layer-option-radio ${
									bl.selected ? "selected" : ""
								}`}
								key={bl.type}
								onClick={() => {
									baseLayersTypeClick(bl);
								}}
							>
								{bl.selected}
								{bl.title_key === "spi"
									? "SPI"
									: t(bl.title_key)}
							</div>
						);
					})}
				</div>
			</div>
			<div className="options">
				<div className="select-title">{t("base_layer")}</div>
				<div>
					<div className="layer-option-select">
						<Dropdown
							options={options}
							value={baseLayerValue}
							onChange={onChange}
							onToggle={onToggle}
							onClear={onClear}
							placeholder={placeholderText}
							autocomplete={false}
							showClose={showClose}
						></Dropdown>
					</div>
				</div>
			</div>
			{selectedType && selectedType.type == "spi" && (
				<div className="options">
					<div className="select-title quartile">
						{t("spi_quartile")}
					</div>
					<div className="layer-option-select quartile">
						<Dropdown
							options={quartileOptions}
							value={selectedQuartile}
							onChange={onQuartileChanged}
							onClear={onQuartileClear}
							placeholder="All"
							autocomplete={false}
						></Dropdown>
					</div>
				</div>
			)}
			<div style={{ clear: "both" }}></div>
		</div>
	);
};
export default BaseLayersOptions;
