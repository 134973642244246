import axios from "axios";
import { URL } from "../constants";

export default axios.create({
	baseURL: URL,
	headers: {
		"Content-Type": "application/json",
		Authorization: `Bearer 3c9b2c72a3bc31e518e89368825796b4c9da1668b96cc7e8acddce8253bcbf89`,
	},
	withCredentials: true,
});
