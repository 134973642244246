import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import myData from "../../lib/ctmapping.json";

interface Props {
	CensusTract: string;
}

const CensusTractTitle = ({ CensusTract }: Props) => {
	const [data, setData] = useState<any>();
	const [loading, setLoading] = useState<Boolean>(false);

	useEffect(() => {
		setLoading(true);
		setData(myData);
		setLoading(false);
	}, []);

	const { t } = useTranslation();

	if (loading) return <div>Loading.</div>;
	if (!data) return <></>;

	return (
		<div
			className="scorecard-city-zip"
			style={{
				display: "flex",
				flexDirection: "column",
				marginTop: "10px",
			}}
		>
			{data[CensusTract]?.city.length > 0 && (
				<span>
					<strong>
						{data[CensusTract].city.length > 1
							? `${t("cities")}`
							: `${t("city")}`}
						:
					</strong>{" "}
					{data[CensusTract].city.join(", ")}
				</span>
			)}
			<div style={{ marginTop: "10px" }}>
				{data[CensusTract]?.zip.length > 0 && (
					<span>
						<strong>
							{data[CensusTract].zip.length > 1
								? `${t("zip_codes")}`
								: `${t("zip_code")}`}
							:
						</strong>{" "}
						{data[CensusTract].zip.join(", ")}
					</span>
				)}
			</div>
		</div>
	);
};

export default CensusTractTitle;
